<messages>["./components/WDRP"]</messages>

<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-card>
        <v-card-title primary-title>
          <div>
            <div
              v-t="'label.download'"
              class="text-h5"/>
            <div
              v-t="'label.subDownload'"
              class="cgwng-subheading"/>
          </div>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-btn :href="downloadLink" :loading="isLoading" :disabled="error">
                {{ $t ('general.button.download') }}
                <v-icon>get_app</v-icon>
              </v-btn>
            </v-col>
            <v-col v-if="meta && !error" cols="12">
              {{ $t ('label.created') }} {{ formatDateShort (meta.lastModified) }}, {{ bytesToSize (meta.size) }}
            </v-col>
            <v-col v-if="error" cols="12">
              <span
                v-t="'label.errors.reportNotFound'"
                class="red--text"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>

  import {mapGetters, mapActions} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'

  export default {
    components: {
      BaseLayout
    },

    data () {
      return {
        isLoading: true,
        error: false,
        meta: null
      }
    },

    computed: {
      ...mapGetters ({
        operationLink: 'request/operationLink'
      }),

      downloadLink () {
        return this.operationLink ({
          op: 'services/download-report',
          params: { }
        })
      }
    },

    created () {
      this.loadMetaData ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadMetaData () {
        this.isLoading = true
        this.error = false
        this.loadedData = null

        this.fetchData ({
          op: 'services/load-report-meta',
          cb: data => {
            this.meta = {
              lastModified: data.lastModified,
              size: data.size
            }
          },

          cbError: (data) => {
            this.error = true
          },

          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      bytesToSize (bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return '0 Byte'
        const i = parseInt (Math.floor (Math.log (bytes) / Math.log (1024)))
        return Math.round (bytes / Math.pow (1024, i), 2) + ' ' + sizes[i]
      }
    }

  }
</script>
